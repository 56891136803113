<template>
  <!-- 订单详情 -->
  <div class="container" :class="{ 'container-spinning': spinning }">
    <a-spin tip="Loading..." v-if="spinning"> </a-spin>

    <a-page-header
      :title="title"
      :sub-title="`订单号：${info.orderInfo.outTradeNo}`"
      @back="() => $router.go(-1)"
    >
      <template slot="extra" v-if="info">
        <a-button
          ghost
          type="danger"
          v-if="info.orderInfo.status === 4"
          @click="onCancelOrder"
        >
          取消订单
        </a-button>
        <a-button
          type="primary"
          v-if="info.orderInfo.status === 4"
          @click="onFinishOrder"
        >
          完成订单
        </a-button>
        <a-button @click="onEditBookingAt" v-if="info.orderInfo.status === 4">
          修改上门时间
        </a-button>
      </template>
    </a-page-header>

    <div class="main-content">
      <div class="content-item">
        <div class="content-item-header">
          <div class="title">订单状态</div>
        </div>
        <a-steps :current="stepsCurrent" :status="stepStatus" progress-dot>
          <a-step
            v-for="item in steps"
            :key="item.title"
            :title="item.title"
            :description="item.description"
          />
        </a-steps>
      </div>

      <div class="content-item" ref="printContent">
        <div class="content-item-header">
          <div class="title">订单详情</div>
          <div class="item print-btn">
            <a-button @click="print">
              打印订单详情
            </a-button>
          </div>
        </div>

        <div class="content-sub-title">概述</div>

        <div class="ul">
          <div class="li">
            <span>订单号：</span>
            <span>{{ info.orderInfo.outTradeNo }}</span>
          </div>
          <div class="li">
            <span>订单类型：</span>
            <span>{{ info.activityName }}</span>
          </div>
        </div>

        <div class="ul ul-2">
          <div class="li">
            <span>服务项目：</span>
            <span>{{ info.orderInfo.catename }}</span>
          </div>
          <div class="li">
            <span>订单状态：</span>
            <span>{{
              $orderSurroundingsStatuText[info.orderInfo.status]
            }}</span>
          </div>
        </div>

        <a-divider />

        <div class="content-sub-title">
          <div class="title-1">上门信息</div>
          <a-button
            type="link"
            @click="onEditBookingAt"
            v-if="info.orderInfo.status < 3"
          >
            修改上门时间
          </a-button>
        </div>

        <template v-if="info.address">
          <div class="ul">
            <div class="li">
              <span>预约上门时间：</span>
              <span>{{ info.orderInfo.bookingAt }}</span>
            </div>
            <div class="li">
              <span>上门联系人：</span>
              <span>{{ info.address.name }}</span>
            </div>
            <div class="li">
              <span>联系电话：</span>
              <span>{{ info.address.mobile }}</span>
            </div>
          </div>
          <div class="ul ul-2">
            <div class="li address">
              <span>预约上门地址：</span>
              <span>
                {{ info.address.address }}
              </span>
            </div>
          </div>
        </template>
        <a-divider />

        <div class="content-sub-title">下单服务</div>

        <a-table
          :columns="columns"
          :data-source="info.servingInfo"
          :pagination="false"
          :row-key="(record) => record.id"
          :scroll="{ x: 1050 }"
        >
          <template slot="price" slot-scope="records">
            <a-statistic prefix="￥" :value="records.price" />
          </template>
        </a-table>
      </div>

      <div class="content-item" v-if="info.orderUser">
        <div class="content-item-header">
          <div class="title">下单用户</div>
          <div class="item print-btn">
            <a-button @click="onUserInfoOpen">
              用户详情
            </a-button>
          </div>
        </div>

        <div class="ul">
          <div class="li">
            <span>下单用户：</span>
            <span>{{ info.orderUser.name }}</span>
          </div>
          <div class="li">
            <span>注册手机号：</span>
            <span>{{ info.orderUser.mobile }}</span>
          </div>
          <div class="li">
            <span>下单次数：</span>
            <span>{{ info.orderUser.orderNum }}</span>
          </div>
        </div>
        <div class="ul ul-2">
          <div class="li">
            <span>用户编号：</span>
            <span>{{ info.orderInfo.userId }}</span>
          </div>
        </div>

        <template v-if="info.orderInfo.status === 6">
          <a-divider />
          <div class="content-sub-title">用户评价</div>
          <div class="ul">
            <div class="li">
              <span>订单评价：</span>
              <a-rate v-model="info.orderInfo.grade" disabled />
            </div>
            <div class="li">
              <span>评价时间：</span>
              <span>{{ info.orderInfo.gradeAt }}</span>
            </div>
          </div>
        </template>
      </div>

      <div class="content-item">
        <div class="content-item-header">
          <div class="title">收款明细</div>
        </div>
        <div class="content-sub-title">金额概览</div>
        <div class="ul">
          <div class="li">
            <span>订单总金额：</span>
            <a-statistic
              class="a-statistic"
              prefix="￥"
              :value="info.orderInfo.totalFee"
            />
          </div>
          <div class="li">
            <span>实际付款金额：</span>
            <a-statistic
              class="a-statistic"
              prefix="￥"
              :value="info.orderInfo.paidFee"
            />
          </div>
          <div class="li">
            <span>优惠金额：</span>
            <a-statistic
              class="a-statistic"
              prefix="￥"
              :value="info.orderInfo.discountFee"
            />
          </div>
        </div>
        <template v-if="info.couponInfo">
          <a-divider />
          <div class="content-sub-title">优惠明细</div>
          <div class="ul">
            <div class="li">
              <span>代金券类型：</span>
              <span>{{ $stockTypes[info.couponInfo.type] }}</span>
            </div>
            <div class="li">
              <span>代金券名称：</span>
              <span>{{ info.couponInfo.name }}</span>
            </div>
            <div class="li">
              <span>代金券面值：</span>
              <span>{{ info.couponInfo.amount }}元</span>
            </div>
          </div>
          <div class="ul ul-2">
            <div class="li">
              <span>代金券ID：</span>
              <span>{{ info.couponInfo.promotion_id }}</span>
            </div>
            <div class="li">
              <span>批次ID：</span>
              <span>{{ info.couponInfo.activity_id }}</span>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div class="embedded-page-wrap" v-if="isUserInfo">
      <div class="embedded-page">
        <div class="embedded-page-header">
          <div class="embedded-page-header-text">用户详情</div>
          <div class="embedded-page-close-btn" @click="onUserInfoClose">
            <a-icon type="close" />
          </div>
        </div>
        <user-info />
      </div>
    </div>

    <a-modal
      title="修改上门时间"
      :visible="modalVisible"
      :confirm-loading="modalConfirmLoading"
      @ok="handleModalOk"
      @cancel="handleModalCancel"
    >
      <a-date-picker show-time placeholder="上门时间" v-model="datePicker" />
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import userInfo from './user-info'
import { formatTime } from '../../utils/public'
import moment from 'moment'
import NP from 'number-precision'
let servingInfoLength = 0
const renderContent = (text, record, index) => {
  const obj = {
    children: text,
    attrs: {}
  }
  if (index === servingInfoLength) {
    obj.attrs.colSpan = 0
  }
  return obj
}
export default {
  data() {
    return {
      spinning: true,
      title: '',
      info: {
        orderInfo: {
          outTradeNo: ''
        }
      },
      stepsCurrent: 0,
      stepStatus: 'process',
      steps: [
        {
          title: '提交订单',
          description: ''
        },
        {
          title: '支付订单',
          description: ''
        },
        {
          title: '处理中',
          description: ''
        },
        {
          title: '订单完成',
          description: ''
        }
      ],
      columns: [
        {
          title: '序号',
          width: 100,
          dataIndex: 'sort',
          customRender: (text, record, index) => {
            if (index < servingInfoLength) {
              return index + 1
            }
            return {
              children: <span style="font-weight: bold;">{text}</span>,
              attrs: {
                colSpan: 4
              }
            }
          }
        },
        {
          title: '服务项目',
          dataIndex: 'servingName',
          width: 200,
          customRender: renderContent
        },
        {
          title: '服务名称',
          dataIndex: 'infoName',
          width: 200,
          customRender: renderContent
        },
        {
          title: '服务简述',
          dataIndex: 'subtitle',
          customRender: renderContent
        },
        {
          title: '服务次数',
          dataIndex: 'quantity',
          width: 150
        },
        {
          title: '金额',
          width: 100,
          scopedSlots: { customRender: 'price' }
        }
      ],
      quantity: 0,
      isUserInfo: false,
      datePicker: '',
      modalVisible: false,
      modalConfirmLoading: false
    }
  },
  computed: {
    ...mapState(['$stockTypes', '$orderSurroundingsStatuText'])
  },
  components: {
    userInfo
  },
  created() {
    this.title = this.$getPageTitle()
    const id = this.$route.query.id
    this.getOrderSurroundingsInfo(id)
  },
  methods: {
    getOrderSurroundingsInfo(id) {
      const data = {
        orderId: id
      }
      this.$axios.getOrderSurroundingsInfo(data).then((res) => {
        const d = res.data.data
        if (d.couponInfo) {
          d.couponInfo.amount = NP.divide(d.couponInfo.amount, 100)
        }
        servingInfoLength = d.servingInfo.length
        const status = d.orderInfo.status // 订单状态 0：待支付 4：处理中 5：完成 8：平台取消订单
        const times = [
          'createAt', // 创建时间
          'payAt', // 用户支付时间
          'servingAt', // 服务时间
          'finishAt' // 完成时间
        ]
        this.steps.forEach((e, i) => {
          if (d.orderInfo[times[i]]) {
            const t = formatTime(d.orderInfo[times[i]])
            e.description = t
          }
        })
        const statusIndex = {
          4: 2,
          5: 3,
          8: 3
        }
        if (status < 6) {
          this.stepsCurrent = statusIndex[status]
        } else {
          this.stepsCurrent = 3
          const step = this.steps[3]
          step.title = this.$orderSurroundingsStatuText[status]
          step.description = formatTime(d.orderCancel.finshAt)
          this.stepStatus = 'error'
        }

        let quantity = 0
        d.servingInfo.forEach((e) => {
          quantity += Number(e.quantity)
        })
        const row = {
          id: 0,
          sort: '总计',
          servingName: '',
          infoName: '',
          subtitle: '',
          quantity: quantity,
          price: d.orderInfo.totalFee
        }
        d.servingInfo.push(row)
        this.info = d
        this.spinning = false
      })
    },
    print() {
      // 打印
      const content = this.$refs.printContent.innerHTML
      const printDiv = document.createElement('div')
      printDiv.innerHTML = content
      printDiv.style.position = 'fixed'
      printDiv.style.left = '0'
      printDiv.style.top = '0'
      printDiv.style.width = '100%'
      printDiv.style.height = '100%'
      printDiv.style.zIndex = '100000'
      printDiv.style.background = '#fff'
      printDiv.style.overflow = 'auto'
      document.body.appendChild(printDiv)
      window.print()
      document.body.removeChild(printDiv)
    },
    onUserInfoClose() {
      // 关闭用户详情
      this.$router.replace({
        query: {
          id: this.info.orderInfo.orderId
        }
      })
      this.isUserInfo = false
    },
    onUserInfoOpen() {
      // 查看用户详情
      this.$router.replace({
        query: {
          id: this.info.orderInfo.userId
        }
      })
      this.isUserInfo = true
    },
    onEditBookingAt() {
      // 修改上门时间
      this.modalVisible = true
    },
    handleModalCancel() {
      // 取消修改上门时间
      this.datePicker = ''
      this.modalVisible = false
      this.modalConfirmLoading = false
    },
    handleModalOk() {
      // 确定修改上门时间
      this.modalConfirmLoading = true
      const data = {
        orderId: this.info.orderInfo.orderId,
        bookingAt: moment(this.datePicker).format('YYYY-MM-DD HH:mm:ss')
      }
      this.$axios
        .changeOrderSurroundingsTime(data)
        .then(() => {
          this.modalConfirmLoading = false
          this.$message.success('操作成功')
          this.handleModalCancel()
          this.getOrderSurroundingsInfo(this.info.orderInfo.orderId)
        })
        .catch(() => {
          this.handleModalCancel()
        })
    },
    onCancelOrder() {
      // 取消订单
      this.$confirm({
        title: '确定取消订单?',
        onOk: () => {
          const data = {
            orderId: this.info.orderInfo.orderId
          }
          this.$axios.cancelOrderSurroundings(data).then(() => {
            this.$message.success('操作成功')
            this.getOrderSurroundingsInfo(this.info.orderInfo.orderId)
          })
        }
      })
    },
    onFinishOrder() {
      // 完成订单
      this.$confirm({
        title: '确定完成订单?',
        onOk: () => {
          const data = {
            orderId: this.info.orderInfo.orderId
          }
          this.$axios.finishOrderSurroundings(data).then(() => {
            this.$message.success('操作成功')
            this.getOrderSurroundingsInfo(this.info.orderInfo.orderId)
          })
        }
      })
    }
  }
}
</script>

<style src="../../assets/css/info-page.css" scoped />
<style scoped>
.container {
  position: relative;
}

.container-spinning {
  height: 100vh;
  overflow: hidden;
}

.ant-spin {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.ant-page-header {
  position: inherit;
  z-index: 10;
}

.content-item:first-child {
  padding: 0;
}

.content-item:first-child .content-item-header {
  padding: 16px 32px;
  border-bottom: 1px solid #e9e9e9;
}

.content-sub-title,
.content-item-header .title {
  display: flex;
  align-items: center;
}

@media print {
  .print-btn {
    display: none;
  }
}
</style>
